
import UpdateExperienceDialog from './UpdateExperienceDialog.vue';
import Row from '@/components/cards/CardRow.vue';
import {
  Experience,
  showAttribute,
  getLabelForTitle,
  ExperienceType,
} from '@/types/experience';
import Vue, { PropType } from 'vue';
import { CardRowType } from '@/types/shared';
import { getPeriodString } from '@/utilities/DateAndTime';
import { notEmpty } from '@/utilities/Utils';
export default Vue.extend({
  data() {
    return {
      CardRowType,
    };
  },
  props: {
    experienceItems: { type: Array as PropType<Experience[]> },
    index: { type: Number },
    type: { type: String },
    sectionTitle: { type: String },
    isAddDialog: { type: Boolean },
    userType: { type: String },
    disableEdit: { type: Boolean },
  },
  computed: {
    selectedAreas() {
      const areaCategories =
        this.experienceItems[this.index].areas
          ?.map((area) => area.areaCategory)
          .filter(notEmpty)
          .map((category) => category.name) ?? [];

      const areas =
        this.experienceItems[this.index].areas?.map((a) => a.name) ?? [];
      return [...[...new Set(areaCategories)], ...areas];
    },
    titleLabel(): string {
      return getLabelForTitle(this.experienceItems[this.index]);
    },
    experienceItem(): Experience {
      return this.experienceItems[this.index];
    },
    period() {
      const start = this.experienceItems[this.index].timeStart;
      const end = this.experienceItems[this.index].timeEnd;

      return getPeriodString(start, end, true, true);
    },
  },
  methods: {
    isVisible(row: string): boolean {
      return showAttribute(this.experienceItems[this.index], row);
    },
  },
  components: {
    Row,
    UpdateExperienceDialog,
  },
});
