<template lang="pug">
    div 
        v-list-item
            v-list-item-content
                div.d-flex.flex-row.justify-space-between.align-center.flex-wrap
                    div
                        v-list-item-title
                        span {{item.name}}
                    div()
                        SkillLevel(v-if="item.level > 0" :value='item' @input='levelChangedHandler' :readonly='false')
            v-list-item-action
                v-checkbox(@change='skillCheckboxChanged($event)' :input-value='item.level > 0' :rules="[v => ( value.length > 0) || 'Pflichtfeld']")
</template>
<script>
import { cloneDeep } from 'lodash';
import SkillLevel from './SkillLevel';
export default {
  name: 'UpdateSkillFormItem',
  components: { SkillLevel },
  props: ['value', 'skill', 'level'],
  data() {
    return {
      //   isSelected: false,
      item: {},
    };
  },
  created() {
    this.item = cloneDeep(this.skill);
    if (this.level) {
      this.item.level = this.level;
    }
  },
  methods: {
    levelChangedHandler(skill) {
      this.$emit('skillChanged', skill);
    },
    skillCheckboxChanged(selected) {
      const skill = this.item;
      if (selected) {
        const item = {
          name: skill.name,
          code: skill.code,
          level: 1,
        };
        this.item = item;
        this.$emit('skillChanged', item);
      } else {
        const item = {
          name: skill.name,
          code: skill.code,
        };
        this.item = item;
        this.$emit('skillChanged', item);
      }
    },
  },
};
</script>
