<template lang="pug">
div.pt-4
        //- TITLE
        InputRow(:title="titleLable" icon="$country" v-if="isVisible('title') && type === ExperienceType.OTHER_EXPERIENCE")
            SelectDialog(slot="inputSlot"  :label="titleLable" :customText="true" :items='otherExperienceTypes' v-model='experienceItem.title'  :multiple="false" :required="true" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" )
        InputRow(:title="titleLable" icon="$country" v-if="isVisible('title') && type !== ExperienceType.OTHER_EXPERIENCE")
            v-text-field(slot="inputSlot" :label="titleLable" dense hide-details outlined v-model='experienceItem.title'  append-icon="mdi-pencil" required :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']")
        InputRow(title="Anstellungsart" icon="$country" v-if="isVisible('activity')")
            SelectDialog(slot="inputSlot" label="Anstellungsart" :items="jobTypes"  v-model="experienceItem.activity" :multiple="false" :required="true" :chips="true" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']")
        //- START DATE
        InputRow(title="Beginn" icon="$calendar" v-if="isVisible('period')")
            DatePickerDialog(slot="inputSlot" label="Beginn" v-model="experienceItem.timeStart" type="month" :required="true"  min='1950-01-01' :max="currentDate" )
        //- END DATE
        InputRow(title="Ende" icon="$calendar" v-if="isVisible('period')")
            DatePickerDialog(slot="inputSlot" label="Ende" v-model="experienceItem.timeEnd"  :required="true" type="month" min='1950-01-01' :max="currentDate")
        //- COUNTRY
        InputRow(title="Land" icon="$country" v-if="isVisible('country')")
            SelectDialog(slot="inputSlot" label="Land" :items='countries' :required="true" v-model='experienceItem.countries[0]' :searchItems="true" :returnObject="true" :multiple="false"  itemText="name" itemValue="alpha2" :rules="[v => type !== ExperienceType.OTHER_EXPERIENCE || !!v || 'Pflichtfeld']")
        //- AREAS
        InputRow(title="Bereich" icon="$area" v-if="isVisible('areas')")
            SelectAreaMenu(slot="inputSlot"  :required="true" :areaItems="experienceItem.areas" @areaChanged="areaItemsChanged")
        //- LEVEL 
        InputRow(title="Level"  icon="$country" v-if="isVisible('level')")
            SelectDialog(slot="inputSlot"  label="Level" :items='ExperienceLevels' v-model='experienceItem.level[0]'  :multiple="false" :chips="true" :required="true" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" )
</template>

<script>
import Vue from 'vue';
import InputRow from '@/components/inputs/InputRow';
import { cloneDeep } from 'lodash';
import { countries } from '@/assets/selections/countries';
import otherExperienceTypes from '@/assets/selections/otherExperienceTypes.json';
import ExperienceLevels from '@/assets/selections/experienceLevels';
import SelectAreaMenu from '@/components/dialogs/SelectAreaDialog';
import { showAttribute, getLabelForTitle } from '@/types/experience';
import jobTypes from '@/assets/selections/jobTypes.json';
import { ExperienceType } from '@/types/experience';
import SelectDialog from '@/components/dialogs/SelectDialog.vue';
import DatePickerDialog from '@/components/dialogs/DatePickerDialog.vue';

export default Vue.extend({
  components: { InputRow, SelectAreaMenu, SelectDialog, DatePickerDialog },
  props: [
    'value',
    'type',
    'experienceItems',
    'indexToUpdate',
    'sectionTitle',
    'isAddDialog',
    'userType',
  ],
  data() {
    return {
      otherExperienceTypes,
      ExperienceType,
      ExperienceLevels: ExperienceLevels.offering,

      dialogVisible: false,
      countries: countries,
      jobTypes: jobTypes.lookingFor,
      experienceItem: this.value[this.indexToUpdate]
        ? cloneDeep(this.value[this.indexToUpdate])
        : {
            activity: '',
            countries: [],
            level: [],
            timeEnd: '',
            timeStart: '',
            title: '',
            type: this.type,
            areas: [],
          },
    };
  },
  computed: {
    titleLable() {
      return getLabelForTitle(this.experienceItem);
    },
    currentDate() {
      return new Date().toISOString();
    },
  },
  methods: {
    areaItemsChanged(areas) {
      this.experienceItem.areas = areas;
    },
    isVisible(row) {
      return showAttribute(this.experienceItem, row, true);
    },
  },
  watch: {
    experienceItem: {
      handler() {
        if (this.value[this.indexToUpdate]) {
          this.value[this.indexToUpdate] = this.experienceItem;
        } else {
          this.value.push(this.experienceItem);
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
.gray-caption
  @include uppercase-gray-bold-text(12px)

.status-chip-select
  background-color: inherit !important
  color: gray !important
  border: solid gray 2px !important
  border-radius: 7px !important
  padding: 0px 10px 0px 10px !important
  font-size: 10px !important
  height: 20px !important
  font-weight: 700 !important
  text-transform: uppercase

.status-chip-selected
  color: white !important
  background-color: gray !important
</style>
