<template lang="pug">
.skillForm 
        .searchTextField
          v-text-field.mx-4.mt-2.mt-md-4.mb-2(v-model='search' dense hide-details outlined label='Suche'  rounded append-icon='mdi-magnify')
        v-list.full-height(v-on:scroll.passive="onScroll" subheader )
          .skillSection(v-for='skill, index in filteredSkills' :key="skill.name" :id="skill.name")
            v-divider(v-if="index > 0")
            v-subheader(v-if='subheaderNeeded(skill, index)') {{skill.name[0]}} 
            UpdateSkillFormItem( :skill="skill" v-on:skillChanged="skillChanged" :level="currentLevel(skill)" :value="itemsLocal" )
</template>

<script>
import Software from '@/assets/selections/software.json';
import Languages from '@/assets/selections/languages.json';
import { sortArray } from '@/utilities/Utils';
import SkillLevel from './SkillLevel';
import { SkillType } from '@/types/skill';
import UpdateSkillFormItem from './UpdateSkillFormItem.vue';
import { cloneDeep } from 'lodash';
export default {
  components: { SkillLevel, UpdateSkillFormItem },
  props: ['items', 'type', 'userType', 'formRef'],
  created() {
    this.itemsLocal = cloneDeep(this.items);
  },
  data() {
    const sortedSoftware = sortArray(Software, 'name');
    return {
      search: '',
      dialogVisible: false,
      options: this.type === SkillType.LANGUAGES ? Languages : sortedSoftware,
      itemsLocal: [],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    filteredSkills() {
      const search = this.search.toLowerCase().trim();
      if (!search) return this.options;
      return this.options.filter((item) => {
        return item.name.toLowerCase().indexOf(search) > -1;
      });
    },
    title() {
      return this.type === SkillType.LANGUAGES ? 'Sprachen' : 'Software';
    },
  },

  methods: {
    skillChanged(skill) {
      const index = this.itemsLocal.findIndex((i) => i.name === skill.name);
      if (index > -1) {
        const updated = this.itemsLocal[index];
        updated.level = skill.level;
        if (updated.level && updated.level > 0) {
          this.itemsLocal[index] = updated;
        } else {
          this.itemsLocal.splice(index, 1);
        }
      } else {
        this.itemsLocal.unshift(skill);
        this.formRef?.validate();
      }
      this.$emit('itemsChanged', this.itemsLocal);
    },
    isSelected(skill) {
      return this.itemsLocal
        ? this.itemsLocal.some((item) => item.name === skill.name)
        : false;
    },
    currentLevel(skill) {
      const isSelected = this.itemsLocal
        ? this.itemsLocal.some((item) => item.name === skill.name)
        : false;
      if (isSelected) {
        return this.itemsLocal.find((item) => item.name === skill.name).level;
      }
    },
    subheaderNeeded(skill, index) {
      if (index > 0) {
        return skill.name[0] !== this.filteredSkills[index - 1].name[0];
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>

.skillForm
  position: relative


//TODO: Wenn er Suchfeld auch bei Mobile fixirt haben möchte
// @media only screen and (max-width: 600px)
//   .searchTextField
//     position: fixed
//     background-color: #fff
//     z-index: 1
//     top: 57px
//     left: 0
//     right: 18px
</style>
