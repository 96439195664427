<template lang="pug">
.CardEducationItem
  v-divider.mt-8.mb-4(v-if='index > 0')
  .d-flex.mb-4.align-center.text-left(v-if="type !== 'school'")
    .d-flex.justify-space-between.align-center.full-width
      span.subcaption(style="min-height: 30px") {{ cardTitle }}
      UpdateEducationDialog(:educationItems="educationItems" :type='type' :userType="userType" :indexToUpdate="index" :sectionTitle="cardTitle" :isAddDialog="false" v-if="showEdit")
  Row(:title='titleLabel', :value='educationItem.title', icon='mdi-school' v-if="isVisible('title')" :type="CardRowType.TEXT")
  Row(title='Status', :value='[educationItem.status.toUpperCase()]', icon='mdi-calendar-multiple-check' v-if="isVisible('status')" :type="CardRowType.STATUS")
  Row(title='Abschlussjahr', :value='graduationYear', icon='mdi-calendar' v-if="isVisible('graduationYear')" :type="CardRowType.TEXT")
  Row(title='Land', :value='educationItem.country && educationItem.country.name', icon='mdi-flag' :flag='educationItem.country && educationItem.country.alpha2' v-if="isVisible('country')" :type="CardRowType.TEXT" )
  Row(title='Abschluss', :value='graduation', icon='mdi-school' v-if="isVisible('graduation')" :type="CardRowType.TEXT")
  //- Row(title='Note', :value='educationItem.averageGrade', icon='$averageGrade' v-if="isVisible('averageGrade')" :type="CardRowType.DECIMAL")
  //- Row(title='Schulart', :value='educationItem.level[0]', icon='mdi-school' v-if="isVisible('level')" :type="CardRowType.TEXT")
  Row(title='Bereich', :value='selectedAreas'  icon='mdi-home' border  :type="CardRowType.CHIPS" v-if="isVisible('areas')")
</template>

<script>
import UpdateEducationDialog from './UpdateEducationDialog.vue';

import Row from '@/components/cards/CardRow.vue';
import {
  getLabelForTitle,
  showAttribute,
  EducationStatus,
  EducationType,
} from '@/types/education';
import Vue from 'vue';
import { CardRowType } from '@/types/shared';
import { notEmpty } from '@/utilities/Utils';
import { UserType } from '@/types/user';
export default Vue.extend({
  data() {
    return {
      CardRowType,
    };
  },
  props: [
    'educationItems',
    'type',
    'title',
    'userType',
    'disableEdit',
    'index',
  ],
  computed: {
    selectedAreas() {
      const areaCategories =
        this.educationItem.areas
          ?.map((area) => area.areaCategory)
          .filter(notEmpty)
          .map((category) => category.name) ?? [];

      const areas = this.educationItem.areas?.map((a) => a.name) ?? [];
      return [...[...new Set(areaCategories)], ...areas];
    },
    countryName() {
      return this.educationItem?.country?.name ?? '-';
    },
    educationItem() {
      return this.educationItems[this.index];
    },
    titleLabel() {
      return getLabelForTitle(this.educationItem);
    },
    graduationYear() {
      const date = this.educationItem.graduationYear;
      if (this.educationItem.status === EducationStatus.LAUFEND) {
        return date ? `vsl. ${date}` : '';
      } else {
        return date?.toString() ?? '-';
      }
    },
    graduation() {
      if (this.educationItem.status === EducationStatus.LAUFEND) {
        return this.educationItem.graduation && this.educationItem.graduation[0]
          ? `vsl. ${this.educationItem.graduation[0]}`
          : '';
      } else {
        return this.educationItem.graduation && this.educationItem.graduation[0]
          ? this.educationItem.graduation[0]
          : '-';
      }
    },
    cardTitle() {
      return this.educationItem.title ?? '';
    },
    showEdit() {
      return (
        !this.disableEdit &&
        !(
          this.type == EducationType.SCHOOL &&
          this.userType == UserType.CANDIDATE &&
          this.educationItems.length > 0
        )
      );
    },
  },
  methods: {
    isVisible(row) {
      return showAttribute(this.educationItem, row);
    },
  },
  components: {
    Row,
    UpdateEducationDialog,
  },
});
</script>

<style lang="sass">
@import '@/assets/style/main'
.color-gray
  color: rgba(0, 0, 0, 0.6)
</style>
