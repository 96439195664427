<template lang="pug">
.offerings
  .elements(v-if="!isLoading && !showOnboardingMessage")
    cardPersonalData()
    cardEducation(:type='EducationType.SCHOOL' title='Schule' :educationItems="currentCandidate.school" :userType="UserType.CANDIDATE")
    cardEducation(:type='EducationType.EDUCATION' title='Ausbildung / Studium' :educationItems="orderedEducations" :userType="UserType.CANDIDATE")
    cardExperience(:type='ExperienceType.PRACTICAL_EXPERIENCE' title='Praxiserfahrung' :experienceItems="orderedPracticalExperience" :userType="UserType.CANDIDATE")
    cardExperience(:type='ExperienceType.OTHER_EXPERIENCE' title='Sonstige Erfahrung' :experienceItems="orderedOtherExperience" :userType="UserType.CANDIDATE")
    cardSkill(:type='SkillType.LANGUAGES' title='Sprachen' :skillItems="orderedLanguages" :userType="UserType.CANDIDATE")
    cardSkill(:type='SkillType.SOFTWARE' title='Software' :skillItems="orderedSoftwareSkills" :userType="UserType.CANDIDATE")
  StartOnboardingCard(v-else-if="!isLoading && showOnboardingMessage" v-on="$listeners")
</template>

<script>
import cardEducation from '@/components/candidate/offering/educations/CardEducation.vue';
import cardExperience from '@/components/candidate/offering/experiences/CardExperience.vue';
import cardPersonalData from '@/components/candidate/offering/personal/CardPersonalData.vue';
import cardSkill from '@/components/candidate/offering/skills/CardSkill.vue';
import StartOnboardingCard from '@/components/cards/StartOnboardingCard';
import { SkillType } from '@/types/skill';
import { EducationType } from '@/types/education';
import { ExperienceType } from '@/types/experience';
import { UserType } from '@/types/user';
import {
  orderEducationItems,
  orderExperienceItems,
  orderSkillItems,
} from '@/utilities/Utils';

export default {
  components: {
    cardExperience,
    cardPersonalData,
    cardSkill,
    cardEducation,
    StartOnboardingCard,
  },
  data() {
    return {
      orderEducationItems,
      isLoading: true,
      SkillType,
      ExperienceType,
      EducationType,
      UserType,
    };
  },
  computed: {
    currentCandidate() {
      return this.$store.state.candidate.candidate;
    },
    schoolItems() {
      return this.$store.state.candidate.candidate?.school ?? [];
    },
    orderedEducations: function () {
      return orderEducationItems(this.currentCandidate.education);
    },
    orderedPracticalExperience: function () {
      return orderExperienceItems(this.currentCandidate.practicalExperience);
    },
    orderedOtherExperience: function () {
      return orderExperienceItems(this.currentCandidate.otherExperience);
    },
    orderedLanguages: function () {
      return orderSkillItems(this.currentCandidate.languages);
    },
    orderedSoftwareSkills: function () {
      return orderSkillItems(this.currentCandidate.softwareSkills);
    },
    showOnboardingMessage() {
      return this.$store.getters['user/showOnboardingMessage'];
    },
  },
  async created() {
    window.scrollTo(0, 0);
    await this.$store
      .dispatch('candidate/fetchCurrentCandidate')
      .finally(() => (this.isLoading = false));
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
.offerings
  margin-bottom: 60px
.margin-top
  margin-top: 20px

.right-col
  padding-left: 20px
</style>
