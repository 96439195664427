<template lang="pug"> 
v-row(align="center" justify="center" v-if="currentUser")
      v-col(cols='6' sm='3')
        div(style="position: relative; width: 110px")
          v-avatar.profile-picture(size='110')
              Avatar(:src="currentUser.photoURL")
          v-btn.removeImageBtn(v-if="currentUser && currentUser.photoURL" fab small depressed @click="removeImage")
            v-icon(small) mdi-close
      v-col(cols='6' sm='9')
          UploadAvatarImage(type="profileImage" @onImage="receivedImage")
      v-col(cols='12' md='6')
          v-text-field(label='Vorname' dense v-model="currentUser.firstName" outlined hide-details append-icon="mdi-pencil" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" required)
      v-col(cols='12' md='6')
          v-text-field(label='Nachname' dense v-model="currentUser.lastName" outlined hide-details append-icon="mdi-pencil" :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']" required)
      v-col(cols='12')
          DatePickerDialog(label="Geburtstag" v-model="currentUser.birthdate"  :required="true" :max='new Date().toISOString().substr(0, 10)' min='1950-01-01', :startWithYear="true" :showAge="true")

      v-col(cols='12')
          SelectDialog(label="Staatsangehörigkeit" :items='nationalities' v-model='currentUser.nationality' placeholder="Deutschland" :searchItems="true" :returnObject="true" :multiple="false"  itemText="name" itemValue="alpha2")
      
      v-col(cols='12' )
          LocationSelector(label='Wohnort' v-model="currentUser.city")
          
      v-col.px-4(cols='12')
          toggleButtons(:data='genderSelectionOptions' @setSelection="genderUpdated", :currentValue="currentGender")

</template>
<script>
import UploadAvatarImage from '@/components/dialogs/UploadAvatarImage';
import toggleButtons from '@/components/inputs/ToggleButtons';
import { Gender } from '@/types/user';
import LocationSelector from '../../../inputs/locationSelector.vue';

import { countries } from '@/assets/selections/countries';
import Avatar from '@/components/elements/Avatar.vue';
import SelectDialog from '@/components/dialogs/SelectDialog.vue';
import DatePickerDialog from '@/components/dialogs/DatePickerDialog.vue';
import { cloneDeep } from 'lodash';
// import Vue from 'vue';
window.locale = 'de';
export default {
  components: {
    UploadAvatarImage,
    toggleButtons,
    LocationSelector,
    Avatar,
    SelectDialog,
    DatePickerDialog,
  },
  props: ['value'],
  data: () => ({
    dialog: false,
    city: '',
    nationality: '',
    nationalities: countries,
    genderSelectionOptions: [
      {
        title: Gender.FEMALE,
        type: Gender.FEMALE,
        icon: 'mdi-gender-female',
      },
      { title: Gender.MALE, type: Gender.MALE, icon: 'mdi-gender-male' },
      {
        title: Gender.OTHER,
        type: Gender.OTHER,
        icon: 'mdi-gender-transgender',
      },
    ],
    currentUser: null,
  }),
  created() {
    this.currentUser = cloneDeep(this.value);
  },
  computed: {
    currentGender() {
      const current = this.genderSelectionOptions.findIndex(
        (item) => item.type === this.currentUser.gender
      );
      return current;
    },
  },
  watch: {
    currentUser: {
      deep: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    receivedImage(url) {
      this.currentUser.photoURL = url;
    },
    removeImage() {
      this.currentUser.photoURL = '';
    },
    save(date) {
      this.$refs.dateDialog.save(date);
    },
    genderUpdated(gender) {
      this.currentUser.gender = gender ? gender.type : Gender.UNSPECIFIED;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/style/main';
</style>
