<template lang="pug">
v-card-text.dialog-card-text
    InputRow(title="Bezeichnung" v-if="isVisible('title')")
        v-text-field( slot="inputSlot" label="Bezeichnung" dense hide-details outlined v-model='educationItem.title' append-icon="mdi-pencil" required :rules="[v => ( (v && v.length > 0)) || 'Pflichtfeld']")
    //- LEVEL 
    //- InputRow(title="Schulart"  v-if="isVisible('level')")
    //-     v-select(slot="inputSlot" label="Schulart" dense hide-details outlined clearable :items='schoolTypes' v-model='educationItem.level[0]')
    //- TITLE
    InputRow(title="Status" icon="$country" v-if="isVisible('status')")
        v-chip-group(slot="inputSlot" v-model='educationItem.status' )
          v-chip.status-chip.status-chip-select(v-for='chip in EducationStatus' :value="chip" :key='chip' :class="{'error-status': (!validForm && !educationItem.status ) }" active-class='status-chip-selected' )
            | {{chip}}
        v-text-field.d-none(slot="inputSlot" label="Status" readonly dense hide-details outlined v-model='educationItem.status' required :rules="[v => ( v && v.length > 0 ) || 'Pflichtfeld']")
    //- GRADUATION YEAR
    InputRow(title="Abschlussjahr" icon="$calendar" v-if="isVisible('graduationYear')")
      SelectDialog(slot="inputSlot" label="Abschlussjahr" :items='graduationYears' v-model='educationItem.graduationYear' :multiple="false")
    //- COUNTRY
    InputRow(title="Land" icon="$country" v-if="isVisible('country')")
        //- v-autocomplete(slot="inputSlot" label="Land" dense v-model="educationItem.country" :items="countries" item-text="name" item-value="alpha2" return-object outlined hide-details required)
        SelectDialog(slot="inputSlot" label="Land" :items='countries' v-model='educationItem.country' :searchItems="true" :returnObject="true" :multiple="false"  itemText="name" itemValue="alpha2")
    //- GRADUATION
    InputRow(title="Abschluss" v-if="isVisible('graduation')")
        SelectDialog(slot="inputSlot" label="Abschluss" dense hide-details hide-no-data outlined clearable :items='graduations' v-model='educationItem.graduation[0]' return-object :multiple="false" :required="true" :rules="[v => (v && v.length > 0) || 'Pflichtfeld']")
    //- AVERAGE GRADE
    //- InputRow(title="Note" icon="$averageGrade" v-if="isVisible('averageGrade')")
    //-   v-slider.align-center(slot="inputSlot" hide-details v-model='educationItem.averageGrade' :thumb-size="24" ticks step="0.1"  :min="0.9" :max="6" color="accent")
    //-     template(v-slot:prepend)
    //-       v-text-field(v-model='formatNumberToFixed' hide-details outlined label="Note" dense readonly type='text' style='width: 130px;')
    //- AREAS
    InputRow(title="Bereich" icon="$area" v-if="isVisible('areas')")
        SelectAreaMenu(slot="inputSlot" :required="true" :areaItems="educationItem.areas" @areaChanged="areaItemsChanged")
</template>

<script>
import Vue from 'vue';
import InputRow from '@/components/inputs/InputRow';
import { cloneDeep } from 'lodash';
import { filterSuggestions } from '@/assets/selections/graduation';
import { countries } from '@/assets/selections/countries';
import schoolTypes from '@/assets/selections/schoolTypes.json';
import { yearFromDate } from '@/utilities/DateAndTime';
import SelectAreaMenu from '@/components/dialogs/SelectAreaDialog';
import SelectDialog from '@/components/dialogs/SelectDialog.vue';
import {
  EducationStatus,
  showAttribute,
  getLabelForTitle,
} from '@/types/education';

export default Vue.extend({
  components: { InputRow, SelectAreaMenu, SelectDialog },
  props: ['value', 'type', 'indexToUpdate', 'isAddDialog', 'validForm'],
  data() {
    return {
      index: cloneDeep(this.indexToUpdate),
      countries,
      schoolTypes,
      EducationStatus,
      educationItem: this.value[this.indexToUpdate]
        ? cloneDeep(this.value[this.indexToUpdate])
        : {
            level: [''],
            graduation: [''],
            averageGrade: '',
            status: undefined,
            graduationYear: '',
            type: this.type,
            areas: [],
          },
    };
  },
  computed: {
    titleLable() {
      return getLabelForTitle(this.educationItem);
    },
    graduations() {
      return filterSuggestions(this.educationItem, true);
    },
    graduationYears() {
      const isOngoing = this.educationItem.status === EducationStatus.LAUFEND;
      const currentYear = new Date().getFullYear();
      const max = isOngoing ? currentYear + 20 : currentYear;
      const min = isOngoing ? currentYear : currentYear - 50;
      const years = [];

      for (let i = max; i >= min; i--) {
        years.push('' + i + '');
      }

      return years;
    },
    formatNumberToFixed: {
      get: function () {
        return !this.educationItem.averageGrade ||
          this.educationItem.averageGrade < 1
          ? '-'
          : Number.parseFloat(this.educationItem.averageGrade)
              .toFixed(1)
              .replace(/\./g, ',');
      },
    },
  },
  methods: {
    yearFromDate,
    isVisible(row) {
      return showAttribute(this.educationItem, row, true);
    },
    areaItemsChanged(areas) {
      this.educationItem.areas = areas;
    },
  },
  watch: {
    educationItem: {
      handler() {
        const updatedItem = cloneDeep(this.educationItem);
        if (updatedItem.averageGrade < 1) {
          updatedItem.averageGrade = undefined;
        }

        // remove invisible form values
        if (!this.isVisible('title')) updatedItem.title = null;
        if (!this.isVisible('level')) updatedItem.level = [];
        if (!this.isVisible('status')) updatedItem.status = null;
        if (!this.isVisible('graduationYear'))
          updatedItem.graduationYear = null;
        if (!this.isVisible('country')) updatedItem.country = null;
        if (!this.isVisible('graduation')) updatedItem.graduation = [];
        if (!this.isVisible('averageGrade')) updatedItem.averageGrade = null;
        if (!this.isVisible('areas')) updatedItem.areas = [];

        if (this.value[this.index]) {
          this.value[this.index] = updatedItem;
        } else {
          const newLength = this.value.push(updatedItem);
          this.index = newLength - 1;
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
.gray-caption
  @include uppercase-gray-bold-text(12px)

.status-chip-select
  background-color: inherit !important
  color: gray !important
  border: solid gray 2px !important
  border-radius: 7px !important
  padding: 0px 10px 0px 10px !important
  font-size: 10px !important
  height: 20px !important
  font-weight: 700 !important
  text-transform: uppercase
  &.error-status
    color: var(--v-error-base) !important
    border: solid var(--v-error-base) 2px !important

.status-chip-selected
  color: white !important
  background-color: gray !important
</style>
