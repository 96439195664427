<template lang="pug">
CardMobileDesktop(:isLoading="isLoading" :title="title")
  UpdateEducationDialog(slot="actionSlot" :educationItems="educationItems" :type='type' :userType="userType"  :sectionTitle="title" :isAddDialog="true" v-if="showAddDialog" )
  UpdateEducationDialog(slot="actionSlot" :educationItems="educationItems" :type='type' :userType="userType"  :sectionTitle="title" :isAddDialog="false" v-if="showEditDialog" :indexToUpdate="0")
  div.pt-1.pb-2(slot="inputSlot" :class="{'pt-3': (!mobile && type === 'school') || educationItems.length === 0 }")
    EmptyCardText(v-if='!educationItems ||educationItems.length === 0')
    div(v-else v-for='(entry, index) in educationItems' :key="index")
      CardEducationItem.mb-4(:educationItems="educationItems"  :type='type' :index="index" :userType="userType" :sectionTitle="title" :isAddDialog='false' :disableEdit="disableEdit")
</template>

<script>
import CardEducationItem from './CardEducationItem';
import UpdateEducationDialog from './UpdateEducationDialog.vue';
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
import EmptyCardText from '@/components/cards/EmptyCardText';
import { UserType } from '@/types/user';
import { EducationType } from '@/types/education';
export default {
  components: {
    CardEducationItem,
    UpdateEducationDialog,
    CardMobileDesktop,
    EmptyCardText,
  },
  props: ['educationItems', 'type', 'title', 'userType', 'disableEdit'],
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isLoading() {
      if (this.userType === UserType.BUSINESS) {
        return this.$store.state.business.loading;
      } else {
        return this.$store.state.candidate.loading;
      }
    },
    showAddDialog() {
      return (
        !this.disableEdit &&
        !(
          this.type == EducationType.SCHOOL &&
          this.userType == UserType.CANDIDATE &&
          this.educationItems.length > 0
        )
      );
    },
    showEditDialog() {
      return !this.disableEdit && !this.showAddDialog;
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'



// .status-chip
//   background-color: gray
//   color: white
//   border-radius: 7px
//   padding: 2px 10px 2px 10px
//   text-transform: uppercase
//   font-size: 10px
//   font-weight: 800
.color-gray
  color: rgba(0, 0, 0, 0.6)
</style>
