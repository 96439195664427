<template lang="pug">
CardMobileDesktop(:isLoading="isLoading" title="Persönliche Daten")
  UpdatePersonalDataDialog(slot="actionSlot" v-if="!disableEdit")
  CardPersonalDataItem.my-2(slot="inputSlot" v-if="currentUser" :user="currentUser")

    
</template>

<script>
import toggleButtons from '@/components/inputs/ToggleButtons.vue';
import UpdatePersonalDataDialog from './UpdatePersonalDataDialog';
import { Gender } from '@/types/user';
import { formatDate } from '@/utilities/DateAndTime';
import CardPersonalDataItem from './CardPersonalDataItem';
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
export default {
  components: {
    toggleButtons,
    UpdatePersonalDataDialog,
    CardPersonalDataItem,
    CardMobileDesktop,
  },
  props: ['disableEdit', 'user'],
  data() {
    return {
      genderSelectionOptions: [
        {
          title: Gender.FEMALE,
          type: Gender.FEMALE,
          icon: 'mdi-gender-female',
        },
        { title: Gender.MALE, type: Gender.MALE, icon: 'mdi-gender-male' },
        {
          title: Gender.OTHER,
          type: Gender.OTHER,
          icon: 'mdi-gender-transgender',
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.user ?? this.$store.state.user.currentUser;
    },
    isLoading() {
      return this.$store.state.user.loading;
    },
  },
  methods: {
    formatPersonalData(item) {
      switch (item.caption) {
        case 'Geschlecht':
          return this.mapGender(item.value);
        case 'Geburtstag':
          return formatDate(item.value);
        default:
          return item.value;
      }
    },
    mapGender(gender) {
      switch (gender) {
        case Gender.MALE:
          return 'Männlich';
        case Gender.FEMALE:
          return 'Weiblich';
        case Gender.OTHER:
          return 'Divers';
        default:
          return 'Nicht spezifiziert';
      }
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
// .profile-picture
//   border: solid $light-gray 1px
.color-gray
  color: rgba(0, 0, 0, 0.6)
.left-col
  border-right: solid $light-gray 1px

.caption-text
  @include uppercase-gray-bold-text(14px)

.bottom-line
  // border-bottom: solid $light-gray 1px

.margin-sides
  @media #{map-get($display-breakpoints, 'md-and-up')}
    margin: 0 30px 0 15px !important
    min-height: 116px

.gender-icon
  margin-right: 10px

.gender-tick-icon
  margin: 0 0 0 20px
</style>
