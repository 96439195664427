<template lang="pug">
v-dialog(v-model='dialogVisible' max-width="700"  :scrollable='$vuetify.breakpoint.xs' :fullscreen='$vuetify.breakpoint.xs' :hide-overlay='$vuetify.breakpoint.xs' @click:outside="cancel")
    template(v-slot:activator='{ on, attrs }')
      v-btn.ml-auto(icon v-bind='attrs' v-on='on')
        v-icon(color='accent', size='22px') {{ isAddDialog ? 'mdi-plus-circle-outline' : 'mdi-pencil' }}
    v-form( ref="skillForm" v-model="valid" lazy-validation :key="formKey")   
      v-card(style="height: 100%; position: relative")
        div
          v-toolbar(flat color="transparent")
            v-toolbar-title.text-h6.dialog-title.font-weight-bold
              | {{title}} {{isAddDialog ? ' hinzufügen' : ' bearbeiten'}}
            v-spacer
            v-btn(icon @click='cancel')
              v-icon mdi-close
          v-divider
        v-card-text.flex-grow-1.px-0.pt-0.pb-0(:class='mobile ? "skill-list-mobile" : "skill-list-desktop"')
          UpdateSkillForm.flex-grow-1(v-model='selectedSkills' :items="skillItems" @itemsChanged="skillUpdated" :type='type'  :userType="userType" :formRef="$refs.skillForm")
        
        .sticky
          v-divider
          v-card-actions.px-4
            v-btn(v-if='type === SkillType.SOFTWARE && skillItems.length > 0' icon @click='deleteSkills')
              v-icon(color='accent') mdi-delete-outline
            v-spacer
            v-btn(color='grey' text @click='cancel') Abbrechen 
            v-btn.white--text(color='accent darken-1' depressed @click='saveSkills' :disabled="!valid" )
              | {{ isAddDialog ? 'hinzufügen' : 'speichern' }}
</template>

<script>
import SkillLevel from './SkillLevel';
import { SkillType } from '@/types/skill';
import UpdateSkillForm from './UpdateSkillForm.vue';
import { cloneDeep } from 'lodash';
export default {
  components: { SkillLevel, UpdateSkillForm },
  props: ['skillItems', 'type', 'userType', 'isAddDialog'],
  data() {
    return {
      valid: false,
      SkillType,
      formKey: Math.random(),
      dialogVisible: false,
      selectedSkills: this.skillItems ? cloneDeep(this.skillItems) : [],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    title() {
      return this.type === SkillType.LANGUAGES ? 'Sprachen' : 'Software';
    },
  },
  methods: {
    cancel() {
      this.selectedSkills = this.skillItems ? cloneDeep(this.skillItems) : [];
      this.dismiss();
    },
    deleteSkills() {
      this.selectedSkills = [];
      this.saveSkills();
    },
    dismiss() {
      this.dialogVisible = false;
      this.formKey++;
    },
    skillUpdated(items) {
      this.selectedSkills = items;
    },
    saveSkills() {
      if (!this.$refs.skillForm.validate()) {
        return;
      }

      this.$store
        .dispatch(`${this.userType}/partialUpdate`, {
          item: this.selectedSkills,
          key: [this.type],
        })
        .then(() => {
          this.dismiss();
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@media only screen and (max-width: 640px)
.sticky
  position: sticky
  background: #fff
  bottom: 0
  left: 0
  right: 0

.skill-list-mobile
  overflow-y: scroll
  overflow-x: hidden
  // max-height: calc(100vh - 180px) !important

.skill-list-desktop
  overflow-y: scroll
  overflow-x: hidden
  max-height: calc(100vh - 360px)

// .skill-list-mobile
//   overflow-y: scroll
//   max-height: calc(100vh - 230px)

// .skill-list-desktop
//   overflow-y: scroll
//   max-height: calc(100vh - 360px)
</style>
