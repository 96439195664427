<template lang="pug">

  v-dialog( v-model='dialog'  max-width="700" persistent='' scrollable :fullscreen="mobile" @click:outside="dismiss")
    template(v-slot:activator='{ on, attrs }')
        v-btn.ml-auto(icon v-bind='attrs' v-on='on')
            v-icon(color='accent' size='22px') mdi-pencil
    v-form( ref="personalDataForm" v-model="valid" lazy-validation :key="formKey")   
      v-card
        div
          v-toolbar(flat color="transparent")
            v-toolbar-title.text-h6.dialog-title.font-weight-bold
              | Persönliche Daten bearbeiten
            v-spacer
            v-btn(icon @click='dismiss')
              v-icon mdi-close
          v-divider
        v-card-text.pt-8.flex-grow-1
          UpdatePersonalDataForm(v-model="currentUser")
        v-divider
        v-card-actions.px-4
            v-spacer
            v-btn(color='grey ' text='' @click='dismiss')
                | Abbrechen
            v-btn.white--text(color='accent darken-1' depressed='' :loading="loading" :disabled="!valid" @click='updateUserData')
                | Speichern

</template>
<script>
import toggleButtons from '@/components/inputs/ToggleButtons';
import { cloneDeep } from 'lodash';
import LocationSelector from '../../../inputs/locationSelector.vue';
import UpdatePersonalDataForm from '@/components/candidate/offering/personal/UpdatePersonalDataForm.vue';
window.locale = 'de';
export default {
  components: {
    UpdatePersonalDataForm,
    toggleButtons,
    LocationSelector,
  },
  data: () => ({
    formKey: Math.random(),
    valid: false,
    dialog: false,
    menu: false,
    currentUser: {},
  }),
  computed: {
    loading() {
      return cloneDeep(this.$store.state.user.loading);
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  created() {
    this.setUser();
  },
  methods: {
    setUser() {
      this.currentUser = cloneDeep(this.$store.state.user.currentUser);
    },
    dismiss() {
      this.setUser();
      this.dialog = false;
      this.formKey++;
    },
    updateUserData() {
      if (this.$refs.personalDataForm.validate()) {
        this.$store.dispatch('user/updateUser', this.currentUser).then(() => {
          this.$store.commit('candidate/setGeneratingMatches', true);
          this.dismiss();
        });
      }
    },
  },
};
</script>
