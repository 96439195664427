<template lang="pug">
v-dialog(v-model='dialogVisible' max-width="700" :fullscreen='$vuetify.breakpoint.xs' :hide-overlay='$vuetify.breakpoint.xs' scrollable  @click:outside="cancel")
  template(v-slot:activator='{ on, attrs }')
    v-btn.ml-auto(icon v-bind='attrs' v-on='on')
      v-icon(color='accent' size='22px') {{ isAddDialog ? 'mdi-plus-circle-outline' : 'mdi-pencil' }}
  v-form( ref="educationForm" v-model="valid" lazy-validation :key="formKey")
    v-card.d-flex.flex-column(min-height="70vh")
      div
        v-toolbar(flat color="transparent")
          v-toolbar-title.text-h6.dialog-title.font-weight-bold
            | {{ titleLable }} {{isAddDialog ? ' hinzufügen' : ' bearbeiten'}}
          v-spacer
          v-btn(icon @click='cancel')
            v-icon mdi-close
        v-divider
      
      UpdateEducationForm.pt-8.flex-grow-1(v-model="updatedEducationItems" :type='type' :userType="userType" :indexToUpdate="indexToUpdate" :sectionTitle="sectionTitle" :isAddDialog="false"  :validForm="valid" )   
      v-divider
      v-card-actions.px-4
          v-btn(v-if='!isAddDialog && type !== EducationType.SCHOOL' icon  @click='deleteEducation')
            v-icon(color='accent') mdi-delete-outline
          v-spacer
          v-btn(color='grey' text @click='cancel')
            | Abbrechen
          v-btn.white--text(color='accent darken-1' depressed :disabled="!valid" @click='setEducations')
            | {{ isAddDialog ? 'hinzufügen' : 'speichern' }}
</template>

<script>
import Vue from 'vue';
import InputRow from '@/components/inputs/InputRow';
import { cloneDeep } from 'lodash';
import { countries } from '@/assets/selections/countries';
import schoolTypes from '@/assets/selections/schoolTypes.json';
import SelectAreaMenu from '@/components/dialogs/SelectAreaDialog';
import UpdateEducationForm from '@/components/candidate/offering/educations/UpdateEducationForm';
import {
  EducationStatus,
  getLabelForTitle,
  EducationType,
} from '@/types/education';

export default Vue.extend({
  components: { InputRow, SelectAreaMenu, UpdateEducationForm },
  props: [
    'type',
    'educationItems',
    'indexToUpdate',
    'sectionTitle',
    'isAddDialog',
    'userType',
  ],
  data() {
    return {
      EducationType,
      formKey: Math.random(),
      valid: false,
      graduationYearMenu: false,
      dialogVisible: false,
      countries: countries,
      schoolTypes: schoolTypes,
      educationStatus: EducationStatus,
      updatedEducationItems: cloneDeep(this.educationItems),
    };
  },
  computed: {
    titleLable() {
      return getLabelForTitle(
        this.educationItems[this.indexToUpdate] ?? { type: this.type }
      );
    },
  },
  methods: {
    cancel() {
      this.updatedEducationItems = cloneDeep(this.educationItems);
      this.dismissDialog();
    },
    dismissDialog() {
      this.$refs.educationForm.reset();
      this.dialogVisible = false;
      this.formKey++;
    },
    setEducations() {
      if (this.$refs.educationForm.validate()) {
        const updatedItems = cloneDeep(this.updatedEducationItems);

        this.$store
          .dispatch(`${this.userType}/partialUpdate`, {
            item: updatedItems,
            key: [this.type],
          })
          .then(() => {
            this.dismissDialog();
          });
      }
    },
    deleteEducation() {
      const updatedItems = cloneDeep(this.updatedEducationItems);
      updatedItems.splice(this.indexToUpdate, 1);
      this.$store
        .dispatch(`${this.userType}/partialUpdate`, {
          item: updatedItems,
          key: [this.type],
        })
        .then(() => {
          this.dismissDialog();
        });
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
.gray-caption
  @include uppercase-gray-bold-text(12px)

.status-chip-select
  background-color: inherit !important
  color: gray !important
  border: solid gray 2px !important
  border-radius: 7px !important
  padding: 0px 10px 0px 10px !important
  font-size: 10px !important
  height: 20px !important
  font-weight: 700 !important
  text-transform: uppercase

.status-chip-selected
  color: white !important
  background-color: gray !important
</style>
