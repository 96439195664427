<template lang="pug">
div
  v-divider.my-2(v-if="border")
  v-row
    v-col(cols='6' md="4")
      v-icon.mr-1(v-html='icon', small, v-if="icon")
      span.tag-title {{title}}
    
    SkillLevel(:value='value' :readonly='true')
</template>

<script>
import SkillLevel from './SkillLevel';
import { SkillType } from '@/types/skill';
export default {
  components: { SkillLevel },
  props: ['title', 'value', 'icon', 'type', 'border'],
  data() {
    return { SkillType };
  },
};
</script>

<style lang="sass"></style>
