import { render, staticRenderFns } from "./CardEducationItem.vue?vue&type=template&id=21b7ae17&lang=pug&"
import script from "./CardEducationItem.vue?vue&type=script&lang=js&"
export * from "./CardEducationItem.vue?vue&type=script&lang=js&"
import style0 from "./CardEducationItem.vue?vue&type=style&index=0&id=21b7ae17&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports