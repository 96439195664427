<template lang="pug">
v-dialog(v-model='dialogVisible' max-width="700" :fullscreen='$vuetify.breakpoint.xs' :hide-overlay='$vuetify.breakpoint.xs'  scrollable  @click:outside="cancel")
  template(v-slot:activator='{ on, attrs }')
    v-btn.ml-auto(icon v-bind='attrs' v-on='on')
      v-icon(color='accent' size='22px') {{ isAddDialog ? 'mdi-plus-circle-outline' : 'mdi-pencil' }}
  v-form( ref="experienceForm" v-model="valid" lazy-validation :key="formKey") 
    v-card.d-flex.flex-column(min-height="70vh")
      div
        v-toolbar(flat color="transparent")
          v-toolbar-title.text-h6.dialog-title.font-weight-bold
            | {{ sectionTitle }} {{isAddDialog ? ' hinzufügen' : ' bearbeiten'}}
          v-spacer
          v-btn(icon @click='cancel')
            v-icon mdi-close
        v-divider
      v-card-text.pt-4.flex-grow-1
        UpdateExperienceForm(v-model="updatedExperienceItems" :type='type' :userType="userType" :indexToUpdate="indexToUpdate" :sectionTitle="sectionTitle" :isAddDialog="false"  )
      //- v-spacer    
      v-divider
      v-card-actions.px-4
          v-btn(v-if='!isAddDialog' icon @click='deleteExperience')
            v-icon(color='accent') mdi-delete-outline
          v-spacer
          v-btn(color='grey' text @click='cancel')
            | Abbrechen
          v-btn.white--text(color='accent darken-1' :disabled="!valid" depressed @click='setExperiences')
            | {{ isAddDialog ? 'hinzufügen' : 'speichern' }}
</template>

<script>
import Vue from 'vue';
import InputRow from '@/components/inputs/InputRow';
import { cloneDeep } from 'lodash';
import { countries } from '@/assets/selections/countries';
import otherExperienceTypes from '@/assets/selections/otherExperienceTypes.json';
import ExperienceLevels from '@/assets/selections/experienceLevels';
import { formatDateMonth } from '@/utilities/DateAndTime';
import SelectAreaMenu from '@/components/dialogs/SelectAreaDialog';
import { showAttribute, getLabelForTitle } from '@/types/experience';
import jobTypes from '@/assets/selections/jobTypes.json';
import { ExperienceType } from '@/types/experience';
import UpdateExperienceForm from '@/components/candidate/offering/experiences/UpdateExperienceForm.vue';

export default Vue.extend({
  components: { InputRow, SelectAreaMenu, UpdateExperienceForm },
  props: [
    'type',
    'experienceItems',
    'indexToUpdate',
    'sectionTitle',
    'isAddDialog',
    'userType',
  ],
  data() {
    return {
      formKey: Math.random(),
      valid: false,
      otherExperienceTypes,
      ExperienceType,
      ExperienceLevels: ExperienceLevels.offering,
      formatDateMonth,
      timeStartMenu: false,
      timeEndMenu: false,
      dialogVisible: false,
      countries: countries,
      jobTypes: jobTypes.lookingFor,
      updatedExperienceItems: cloneDeep(this.experienceItems),
    };
  },
  computed: {
    titleLable() {
      return getLabelForTitle(
        this.updatedExperienceItems[this.indexToUpdate] ?? { type: this.type }
      );
    },
  },
  methods: {
    cancel() {
      this.updatedExperienceItems = cloneDeep(this.experienceItems);
      this.dismissDialog();
    },
    dismissDialog() {
      this.$refs.experienceForm.reset();
      this.dialogVisible = false;
      this.formKey++;
    },
    setExperiences() {
      if (this.$refs.experienceForm.validate()) {
        const updatedItems = cloneDeep(this.updatedExperienceItems);

        this.$store
          .dispatch(`${this.userType}/partialUpdate`, {
            item: updatedItems,
            key: this.type,
          })
          .then(() => {
            this.dismissDialog();
          });
      }
    },
    deleteExperience() {
      const updatedItems = cloneDeep(this.updatedExperienceItems);
      updatedItems.splice(this.indexToUpdate, 1);

      this.$store

        .dispatch(`${this.userType}/partialUpdate`, {
          item: updatedItems,
          key: this.type,
        })
        .then(() => {
          this.dismissDialog();
        });
    },
    areaItemsChanged(areas) {
      this.experienceItem.areas = areas;
    },
    isVisible(row) {
      return showAttribute(this.experienceItems[this.indexToUpdate], row, true);
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
.gray-caption
  @include uppercase-gray-bold-text(12px)

.status-chip-select
  background-color: inherit !important
  color: gray !important
  border: solid gray 2px !important
  border-radius: 7px !important
  padding: 0px 10px 0px 10px !important
  font-size: 10px !important
  height: 20px !important
  font-weight: 700 !important
  text-transform: uppercase

.status-chip-selected
  color: white !important
  background-color: gray !important
</style>
