<template lang="pug">
.conditions
    .elements(v-if="getOffering")
      CardConditions(:positionName="getOffering.name" :item="getConditions" :loading="isLoading")
      CardTasks(:items="getOffering.tasks")
      CardCompany( :loading="isLoading")
      CardRecruiter
    EmptyCardPosition(v-else text="Noch keine Stelle hinzugefügt")
      
</template>

<script>
import CardConditions from '@/components/business/offering/conditions/CardConditions.vue';
import CardRecruiter from '@/components/business/offering/recruiter/CardRecruiter.vue';
import CardCompany from '@/components/business/offering/company/CardCompany.vue';
import EmptyCardPosition from '@/components/cards/EmptyCardPosition';
import CardTasks from '@/components/business/offering/tasks/CardTasks.vue';

import { UserType } from '@/types/user';
export default {
  components: {
    CardConditions,
    CardRecruiter,
    CardCompany,
    EmptyCardPosition,
    CardTasks,
  },
  data() {
    return {
      UserType,
    };
  },
  computed: {
    getOffering() {
      const currentPosition = this.$store.state.business.positions.find(
        (position) =>
          position.id === this.$store.state.business.selectedPositionId
      );
      return currentPosition;
    },
    getConditions() {
      const currentPosition = this.$store.state.business.positions.find(
        (position) =>
          position.id === this.$store.state.business.selectedPositionId
      );
      return currentPosition?.offering ?? {};
    },
    isLoading() {
      return this.$store.state.business.loading;
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style lang="sass">
@import '@/assets/style/main'
.conditions
  margin-bottom: 60px
.margin-top
  margin-top: 20px
.right-col
  padding-left: 20px
</style>
